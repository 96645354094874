.admin-teacher-edit{
    box-shadow: 0px 0px 5px 1px #ccc;
    /* background: #ffffff; */
}

.resume-panel {
    position: relative;
}
.resume {
    background: #90c0ca;
    padding: 8px;
    border-radius: 5px;
    box-shadow: 0px 0px 2px 1px #ffffff;
    color: #fff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: auto;
}
.closeBtn {
    position: absolute;
    top: -10px;
    right: -11px;
}

.training-radio-btn-alignment{
    text-align: center;
}

@media only screen and (max-width:414px){
    .training-radio-btn-alignment{
        text-align: unset;
    }
}