.teacher-training-row{
    padding-bottom: 0rem !important;
}
.teacher-training-date-row{
    padding-bottom: 1.5rem !important;
}

.mobile-view{
    display: none;
}

@media print {
    #printbtn {
        display :  none;
    }
}
@media only screen and (max-width:414px){
    .teacher-training-row{
        padding-bottom: 1rem !important;
    }
    .teacher-training-date-row{
        padding-bottom: 1rem !important;
    }
    .mobile-view{
        display: inline-block !important;
    }
}