.view-signature-image {
    width: 100%;
    height: auto;
}

.signature-boundary {
    border: 1px solid #1e869b;
    border-radius: 4px
}

.view-parent-profile-layout {
    padding: 20px;
    border-radius: 5px;
    /* 
    background: linear-gradient(to right, #d0f2ec, #cdcccc);
    box-shadow: 0px 3px 6px 2px #e1e1e1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%); */
}

.no-signature-div {
    text-align: center;
    background: white;
}

.no-signature-img {
    width: 200px;
    height: auto;
}

@media only screen and (max-width: 414px) {
    .view-signature-image {
        width: 100%;
    }
}