.reset-password-form-outline{
    padding: 3rem 2rem;

}
.reset-password-heading{
    text-align: center;
}

.reset-password-btn-section{
    width: 100%;
}
.reset-password-btn{
    width: 100%;
    text-align: center;
}
.btn-reset-password{
    width: 50%;
}