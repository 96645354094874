.dt-button {
  background-color: #21ba45 !important;
  color: #fff !important;
  text-shadow: none !important;
  background-image: none !important;
  border-radius: 4px !important;
  padding: 5px 19px !important;
  border: unset !important;
}

.csv-btn {
  background-color: #21ba45 !important;
  color: #fff !important;
  text-shadow: none !important;
  background-image: none !important;
  border-radius: 4px !important;
  padding: 5px 24px !important;
  border: unset !important;
  margin-bottom: 7px;
}

.dataTables_filter>label>input {
  border-radius: unset !important;
  border: unset !important;
  border-bottom: 2px solid !important;
  margin-left: 20px !important;
  margin-bottom: 12px !important;
  transition: .2s ease-in-out;
}

.dataTables_filter>label>input:focus {
  border-bottom: 2px solid blue !important;
  outline: none !important;

}
.ui.dimmer{
  position: fixed;
}
.view-child::-webkit-scrollbar {
  width: 20px !important;
  height: 13px !important;
}


@media print {
  .wordWrap {
    /* word-wrap: break-word;
    word-break: break-all; */
    white-space: normal;
  }

  table {
    page-break-after: auto;
    table-layout: fixed;
  }

  tr {
    page-break-inside: avoid;
    page-break-after: auto
  }

  td {
    page-break-inside: avoid;
    page-break-after: auto;
    max-width: 140px;
    white-space: normal !important;
  }

  th {
    max-width: 140px;
    white-space: normal;
  }

  thead {
    display: table-header-group
  }

  tfoot {
    display: table-footer-group
  }
}

/* .headcol {
    position: absolute;
    width: 5em;
    left: 0;
    top: auto;
    border-top-width: 1px; 
    margin-top: -1px;
    
  } */
/* .headcol:before {
    content: 'Row ';
  } */