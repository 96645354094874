.add-child-border {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
}

.parents-signature {
  width: 100%;
  height: auto;
}

.file-upload {
  display: inline-flex;
  align-items: center;
}

.file-box {
  position: relative;
  padding-right: 15px;
}

.medical-reports {
  padding: 10px 10px;
  background: #9edcd1;
  border-radius: 5px;
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 18px;
  box-shadow: 0px 0px 2px 1px #9d9b9b;
  cursor: pointer;
}

.close-icon {
  position: absolute;
  top: 7px;
  right: 3px;
  cursor: pointer;
}

.file {
  margin-top: 10px;
}

.custom-file-btn {
  background: #b1b1b1;
  padding: 10px 0px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden !important;
}

.custom-file-input::before {
  content: "Select some files" !important;
  display: inline-block !important;
  background: linear-gradient(to bottom, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}

.custom-file-input:hover::before {
  border-color: black !important;
}

.custom-file-input:active::before {
  background: -webkit-linear-gradient(to bottom, #e3e3e3, #f9f9f9) !important;
}
@media screen and (max-width: 1366px) {
  .view-uploaded-files {
    overflow-x: auto;
    overflow-y: hidden;
  }
  .file-upload {
    margin-top: 18px;
  }
}

@media screen and (max-width: 1333px) {
  .file-upload {
    margin-top: 18px;
  }
}

@media screen and (max-width: 991px) {
  .file-upload {
    margin-top: 38px;
  }
}

@media screen and (max-width: 884px) {
  .file-upload {
    margin-top: 56px;
  }
}

@media screen and (max-width: 767px) {
  .file-upload {
    margin-top: unset;
  }
}
@media screen and (max-width: 800px) {
  .view-uploaded-files {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
}

/* @media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi) { 
    .file-upload{
        margin-top: 18px !important;
    }
} */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .parents-signature {
    width: 100%;
  }
  .tablet-view {
    overflow: auto;
  }

  .view-uploaded-files {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

@media only screen and (max-width: 766px) {
  .add-child-form-mt-2rem {
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 414px) {
  .add-child-border {
    border: unset;
    padding: unset;
    border-radius: 0px;
  }
  .add-child-form-mt-2rem {
    margin-top: 2rem;
  }

  .view-uploaded-files {
    overflow-x: auto;
    overflow-y: hidden;
  }
  .parents-signature {
    width: 100%;
  }
  .tablet-view {
    overflow: hidden;
  }
  .margin-top-5 {
    margin-top: 10px;
  }
  .mt-2rem {
    margin-top: 0.5rem;
  }
}
