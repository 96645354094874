.footer {
    display: inline-block;
    margin-top: 40px;
    width: 100%;
    background-color: white;
    color: black;
    text-align: center;
    height: 40px;
}

.company-name {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
    color: #0070c0;
    letter-spacing: 0.5px;
}

.footer-outline {
    width: 100%;
    height: 15px;
    border: 1.5px solid #325490;
}

.footer-mb{
    margin-bottom: 1.1rem;
}

/* @media only screen and (max-width: 414px) {
    .footer-outline {
        margin-left: 3rem;
        margin-right: 3rem;
    }
} */