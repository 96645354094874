
.forgot-password-form-outline{
    padding: 3rem 2rem;
}

.forgot-password-btn-section{
    width: 100%;
}

.forgot-password-btn{
    width: 100%;
    text-align: center;
}

.mb-20px{
    margin-bottom: 20px;
}
.btn-forgot-password{
    width: 50%;
}
.forgot-password-heading{
    text-align: center;
}