label.switch:focus {
    outline: none;
}

.switch {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 30px;
  }
  
  .switch input {display:none;}
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2ab934;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 5px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #ca2222;
  }
  
  input:focus + .slider {

    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(72px);
    -ms-transform: translateX(72px);
    transform: translateX(72px);
  }
  
  /*------ ADDED CSS ---------*/
  .expired
  {
    display: none;
  }
  
  .expired,.renewal
  {
    color: white;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    font-size: 0.750em;
    font-family: Verdana, sans-serif;
  }
  
  input:checked+ .slider .expired 
  {display: block;}
  
  input:checked + .slider .renewal
  {display: none;}
  
  /*--------- END --------*/
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;}